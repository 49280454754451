export function genDealersState () {
  return {
    isLoading: true,
    isNextLoading: false,
    list: [],
    exportList: [],
    exportLocations: [],
    listOpts: {},
    managedLogins: [],
    pageNumber: 0,
    identity: {},
    accountsBlacklist: [],
  }
}

export default genDealersState
