import { extensionDealersGetters } from './types'
import { Identity } from 'Models/Identity'

export default {
  [extensionDealersGetters.IS_LOADING]: state => state.isLoading,
  [extensionDealersGetters.IS_NEXT_LOADING]: state => state.isNextLoading,

  [extensionDealersGetters.LIST]: state => state.list
    .map(item => new Identity(item)),
  [extensionDealersGetters.LIST_RAW]: state => state.list,
  [extensionDealersGetters.LIST_OPTS]: state => state.listOpts,

  [extensionDealersGetters.EXPORT_LIST]: state => state.exportList
    .map(item => new Identity(item)),
  [extensionDealersGetters.EXPORT_LOCATIONS]: state => state.exportLocations
    .reduce((acc, item) => ({ ...acc, [item.id]: item }), {}),

  [extensionDealersGetters.MANAGED_LOGINS]: state => state.managedLogins,
  [extensionDealersGetters.PAGE_NUMBER]: state => state.pageNumber,
  [extensionDealersGetters.IDENTITY]: state => new Identity(state.identity),

  [extensionDealersGetters.ACCOUNTS_BLACKLIST]: state =>
    state.accountsBlacklist,
  [extensionDealersGetters.CHECK_IS_BLACKLISTED]: (_, getters) => id => {
    const blacklist = getters[extensionDealersGetters.ACCOUNTS_BLACKLIST]
    return Boolean(blacklist.find(el => el.id === id))
  }
}
