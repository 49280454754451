import { extensionDealersMutations } from './types'
import { isEqual } from 'lodash'

export default {
  [extensionDealersMutations.SET_IS_LOADING] (state, value) {
    state.isLoading = value
  },

  [extensionDealersMutations.SET_IS_NEXT_LOADING] (state, value) {
    state.isNextLoading = value
  },

  [extensionDealersMutations.SET_LIST] (state, value) {
    state.list = value
  },

  [extensionDealersMutations.SET_EXPORT_LIST] (state, value) {
    state.exportList = value
  },

  [extensionDealersMutations.SET_EXPORT_LOCATIONS] (state, value) {
    state.exportLocations = value
  },

  [extensionDealersMutations.SET_LIST_OPTS] (state, value) {
    state.listOpts = value
  },

  [extensionDealersMutations.PUSH_LIST] (state, value) {
    state.list.push(...value)
  },

  [extensionDealersMutations.UNSHIFT_LIST] (state, value) {
    state.list.splice(0, 0, value)
  },

  [extensionDealersMutations.REMOVE_ITEM] (state, id) {
    state.list = state.list.filter(el => el.id !== id)
  },

  [extensionDealersMutations.UPDATE_ITEM] (state, item) {
    const index = state.list.findIndex(el => el.id === item.id)
    if (index === -1 || isEqual(item, state.list[index])) return
    state.list.splice(index, 1, item)
  },

  [extensionDealersMutations.SET_MANAGED_LOGINS] (state, value) {
    state.managedLogins = value
  },

  [extensionDealersMutations.SET_PAGE_NUMBER] (state, value) {
    state.pageNumber = value
  },

  [extensionDealersMutations.SET_IDENTITY] (state, value) {
    state.identity = value
  },

  [extensionDealersMutations.SET_ACCOUNTS_BLACKLIST] (state, value) {
    state.accountsBlacklist = value
  },

  [extensionDealersMutations.ADD_TO_BLACKLIST] (state, value) {
    state.accountsBlacklist.push(value)
  },

  [extensionDealersMutations.REMOVE_FROM_BLACKLIST] (state, value) {
    const index = state.accountsBlacklist.findIndex(el => el.id === value)
    state.accountsBlacklist.splice(index, 1)
  },
}
