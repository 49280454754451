export const extensionDealersActions = {
  LOAD_LIST: 'LOAD_LIST',
  LOAD_EXPORT_LIST: 'LOAD_EXPORT_LIST',
  LOAD_EXPORT_LOCATIONS: 'LOAD_EXPORT_LOCATIONS',
  LOAD_ITEM: 'LOAD_ITEM',
  LOAD_MORE: 'LOAD_MORE',
  REMOVE_ITEM: 'REMOVE_ITEM',
  UPDATE_ITEM: 'UPDATE_ITEM',
  UNSHIFT_ITEM: 'UNSHIFT_ITEM',
  LOAD_MANAGED_LOGINS: 'LOAD_MANAGED_LOGINS',
  LOAD_IDENTITY: 'LOAD_IDENTITY',
  UPDATE_COPART_LOGIN: 'UPDATE_COPART_LOGIN',
  LOAD_ACCOUNTS_BLACKLIST: 'LOAD_ACCOUNTS_BLACKLIST',
  ADD_TO_BLACKLIST: 'ADD_TO_BLACKLIST',
  REMOVE_FROM_BLACKLIST: 'REMOVE_FROM_BLACKLIST',
}

export const extensionDealersMutations = {
  SET_IS_LOADING: 'SET_IS_LOADING',
  SET_IS_NEXT_LOADING: 'SET_IS_NEXT_LOADING',
  SET_LIST_OPTS: 'SET_LIST_OPTS',
  SET_LIST: 'SET_LIST',
  SET_EXPORT_LIST: 'SET_EXPORT_LIST',
  SET_EXPORT_LOCATIONS: 'SET_EXPORT_LOCATIONS',
  PUSH_LIST: 'PUSH_LIST',
  UNSHIFT_LIST: 'UNSHIFT_LIST',
  REMOVE_ITEM: 'REMOVE_ITEM',
  UPDATE_ITEM: 'UPDATE_ITEM',
  SET_MANAGED_LOGINS: 'SET_MANAGED_LOGINS',
  SET_PAGE_NUMBER: 'SET_PAGE_NUMBER',
  SET_IDENTITY: 'SET_IDENTITY',
  SET_ACCOUNTS_BLACKLIST: 'SET_ACCOUNTS_BLACKLIST',
  ADD_TO_BLACKLIST: 'ADD_TO_BLACKLIST',
  REMOVE_FROM_BLACKLIST: 'REMOVE_FROM_BLACKLIST',
}

export const extensionDealersGetters = {
  IS_LOADING: 'IS_LOADING',
  IS_NEXT_LOADING: 'IS_NEXT_LOADING',
  LIST: 'LIST',
  EXPORT_LIST: 'EXPORT_LIST',
  EXPORT_LOCATIONS: 'EXPORT_LOCATIONS',
  LIST_OPTS: 'LIST_OPTS',
  LIST_RAW: 'LIST_RAW',
  MANAGED_LOGINS: 'MANAGED_LOGINS',
  PAGE_NUMBER: 'PAGE_NUMBER',
  IDENTITY: 'IDENTITY',
  ACCOUNTS_BLACKLIST: 'ACCOUNTS_BLACKLIST',
  CHECK_IS_BLACKLISTED: 'CHECK_IS_BLACKLISTED',
}
