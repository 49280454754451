export const DEALER_CARD_TABS = Object.freeze({
  attributes: 'attributes',
})

export const FEE_LEVELS = Object.freeze({
  LOW: 'low',
  HIGH: 'high'
})

export const DEALERS_PAGE_LIMIT = 15
export const DEALERS_LIMIT_ERROR_MESSAGE = 'dealers limit exceeded'
